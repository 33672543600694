<template>
    <app-dialog class="app-dialog-info"
        v-model="show"
        v-if="show"

        :max-width="maxWidth"
        :processing="processing"

        @closes="onCloses"
    >
        <template #head>
            <slot name="head">
            </slot>
        </template>

        <template #body>
            <slot name="body">
            </slot>
        </template>
    </app-dialog>
</template>


<script>
import appDialog from '@/components/app-dialog'

export default {
    props: {
        value:      { required: true },
        maxWidth:   { type: [Number, String], default: 540 },
        processing: { type: Boolean, default: false },
    },

    components: {
        appDialog,
    },

    methods: {
        onCloses(is_closes_with_button) {
            this.$emit('closes', is_closes_with_button)
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog {
    &.app-dialog-info {
        .app-dialog-container {
            max-height: none;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {}
</style>
