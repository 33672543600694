<template>
    <app-dialog-info
        class="app-dialog-reserved-msisdns"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Reserved MSISDNs</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <div class="wrap">
                <app-error v-model="errors.show" :message="errors.message" />

                <div class="content">
                    <div class="details-list">
                        <div class="detail">
                            <div class="key">Account Name</div>
                            <div class="value">{{ account_name }}</div>
                        </div>

                        <div class="detail">
                            <div class="key">IMSI Start</div>
                            <div class="value">{{ imsi_start }}</div>
                        </div>

                        <div class="detail">
                            <div class="key">Number of IMSIs + MSISDNs to allocate</div>
                            <div class="value">{{ total }}</div>
                        </div>
                    </div>

                    <div class="msisdns-list" v-if="have_msisdns">
                        <div class="scroll">
                            <div class="msisdn" v-for="(msisdn, index) in msisdns" :key="index">{{ msisdn }}</div>
                        </div>
                    </div>

                    <div class="actions">
                        <button class="btn btn-table-action action-primary action-copy"     :disabled="!have_msisdns" @click="copyToClipboard"><span>Copy to clipboard</span></button>
                        <button class="btn btn-table-action action-primary action-download" :disabled="!have_msisdns" @click="downloadAsCSV"><span>Download as CSV</span></button>
                    </div>
                </div>

                <div class="btns" v-if="showButtons">
                    <button class="btn btn-primary"            :disabled="!have_msisdns" @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel"                           @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import TEST_RESERVATION from './test-reservation.json'

export default {
    props: {
        value:    { required: true,                      },
        maxWidth: { type: [Number, String], default: 610 },

        uuid: { type: String, default: null },

        firstName:        { type: String,           default: null },
        lastName:         { type: String,           default: null },
        imsiStart:        { type: String,           default: null },
        numberToAllocate: { type: [Number, String], default: 0    },

        showButtons: { type: Boolean, default: true },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
    },

    data() {
        return {
            reservation: {
                FirstName: null,
                LastName: null,
                IMSIStart: null,
                ReservedMSISDNs: [],
            },

            loading: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.uuid) {
                this.getReservationInfo()
            } else {
                this.tryToReserve()
            }
        },

        getReservationInfo() {
            this.loading = true

            const delay_in_ms = 1000

            const params = {
                UUID: this.uuid,
            }

            console.log('params', params)

            setTimeout(() => {
                this.reservation = { ...TEST_RESERVATION }

                this.loading = false
            }, delay_in_ms)
        },

        tryToReserve() {
            this.loading = true

            const delay_in_ms = 1000

            const params = {
                FirstName: this.firstName,
                LastName: this.lastName,
                IMSIStart: this.imsiStart,
                NumberToAllocate: this.numberToAllocate,
            }

            console.log('params', params)

            setTimeout(() => {
                this.reservation = { ...TEST_RESERVATION }

                this.loading = false
            }, delay_in_ms)
        },

        copyToClipboard() {
            const el = document.createElement('textarea')

            el.value = this.msisdns.join('\r\n')
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)

            this.$store.dispatch('addToast', {
                message: 'Copied to clipboard!',
                delay: 5000,
            })
        },

        downloadAsCSV() {
            console.log('downloadAsCSV() {')

            /**
             * @todo
             */
        },

        confirm() {
            this.$emit('confirm')
        },

        close() {
            this.$emit('input', false)
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },

        account_name() {
            return [this.reservation.FirstName, this.reservation.LastName].join(' ').trim() || '?'
        },

        imsi_start() {
            return this.reservation.IMSIStart || '?'
        },

        total() {
            return this.reservation.ReservedMSISDNs.length || '?'
        },

        msisdns() {
            return this.reservation.ReservedMSISDNs
        },

        have_msisdns() {
            return this.reservation.ReservedMSISDNs.length > 0
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-reserved-msisdns {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .btns {
            margin-top: 40px;
        }

        p {
            font-weight: normal;
        }

        .details-list {
            margin-bottom: 24px;

            .detail {
                display: flex;
                justify-content: space-between;

                padding: 16px 0;

                border-bottom: 1px solid var(--color-divider);

                .value {
                    color: var(--color-table-secondary);
                }

                &:first-child {
                    padding-top: 0;
                }
            }
        }

        .msisdns-list {
            margin-bottom: 24px;

            padding: 8px 8px 8px 24px;

            border-radius: $border-radius-primary;
            border: solid 1px var(--color-input-border);

            .scroll {
                padding: 4px 0;

                @include webkit-scrollbar-vertical(
                    170px,
                    var(--color-nav-bar-scrollbar-bg),
                    var(--color-nav-bar-scrollbar-thumb-bg),
                );
            }

            .msisdn {
                margin-bottom: 22px;

                font-size: 18px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            .btn {
                display: flex;

                width: auto;
                min-width: 0;
                height: 24px;

                margin-right: 24px;

                font-size: 18px;

                span {
                    @include text-overflow();
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-reserved-msisdns {
        .app-dialog-body {
            .details-list {
                .detail {
                    padding: 8px 0;

                    font-size: 14px;
                }
            }

            .msisdns-list {
                margin-bottom: 16px;
                padding: 8px;

                .msisdn {
                    margin-bottom: 8px;

                    font-size: 14px;
                }
            }

            .actions {
                .btn {
                    margin-right: 16px;

                    span {
                        display: none;
                    }
                }
            }

            .btns {
                margin-top: 16px;
            }
        }
    }
}
</style>