<template>
    <app-dialog-info
        class="app-dialog-confirm-reservation"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Are you sure ?</h2>
        </template>

        <template #body>
            <div class="wrap">
                <div class="content">
                    <p>Click "Confirm" if you wish to Reserve {{ numberToAllocate }} MSISDNs for Preactivation</p>
                </div>

                <div class="btns">
                    <button class="btn btn-primary"            @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'

export default {
    props: {
        value:    { required: true,                      },
        maxWidth: { type: [Number, String], default: 610 },

        numberToAllocate: { type: [Number, String], default: 0 },
    },

    components: {
        appDialogInfo,
    },

    methods: {
        confirm() {
            this.$emit('confirm')
        },

        close() {
            this.$emit('input', false)
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-confirm-reservation {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-confirm-reservation {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>