<template>
    <div class="page-prepay-mobile-preactivation-reserve">
        <div class="board">
            <div class="form-container">
                <div class="form-group">
                    <div class="form-controls">
                        <app-input
                            v-model="first_name"

                            label="First Name"

                            :error="errors.fields.first_name"

                            @change="errors.fields.first_name = null"
                        />
                    </div>

                    <div class="form-controls">
                        <app-input
                            v-model="last_name"

                            label="Last Name"

                            :error="errors.fields.last_name"

                            @change="errors.fields.last_name = null"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-controls">
                        <app-input
                            v-model="imsi_start"

                            label="IMSI Start"

                            :mask="phone_number_mask"

                            :error="errors.fields.imsi_start"

                            @change="errors.fields.imsi_start = null"
                        />
                    </div>

                    <div class="form-controls">
                        <app-input
                            v-model="number_to_allocate"

                            label="Number of IMSIs + MSISDNs to allocate"

                            :mask="number_to_allocate_mask"

                            :error="errors.fields.number_to_allocate"

                            @change="errors.fields.number_to_allocate = null"
                        />
                    </div>
                </div>
            </div>

            <button class="btn btn-primary btn-small" @click="submit">Submit</button>
        </div>

        <app-dialog-confirm-reservation
            v-if="dialogs.confirm_reservation.show"
            v-model="dialogs.confirm_reservation.show"

            :number-to-allocate="number_to_allocate"

            @confirm="onDialogConfirmReservationConfirm"
        />

        <app-dialog-reserved-msisdns
            v-if="dialogs.reserved_msisdns.show"
            v-model="dialogs.reserved_msisdns.show"

            :first-name="first_name"
            :last-name="last_name"
            :imsi-start="imsi_start"
            :number-to-allocate="number_to_allocate"

            @confirm="onDialogReservedMsisdnsConfirm"
        />
    </div>
</template>

<script>
import appInput from '@/components/app-input'

import appDialogConfirmReservation from '../components/app-dialog-confirm-reservation'
import appDialogReservedMsisdns from '../components/app-dialog-reserved-msisdns'

import PrepayMobileValidator from '@/validators/prepay-mobile-validator'

export default {
    components: {
        appInput,

        appDialogConfirmReservation,
        appDialogReservedMsisdns,
    },

    data() {
        return {
            first_name: null,
            last_name: null,
            imsi_start: null,
            number_to_allocate: null,

            // first_name: 'Fuller',
            // last_name: 'Dale',
            // imsi_start: '649174092026',
            // number_to_allocate: '10',

            dialogs: {
                confirm_reservation: {
                    show: false,
                },

                reserved_msisdns: {
                    show: false,
                },
            },

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        submit() {
            if (this.validation()) {
                this.dialogs.confirm_reservation.show = true
            }
        },

        onDialogConfirmReservationConfirm() {
            this.dialogs.confirm_reservation.show = false
            this.dialogs.reserved_msisdns.show    = true
        },

        onDialogReservedMsisdnsConfirm() {
            this.$router.push({ name: 'prepay-mobile-preactivation-previous-reservations' })
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                first_name: { rule: 'FirstName',   message: 'Please, enter valid first name'   },
                last_name:  { rule: 'LastName',    message: 'Please, enter valid last name'    },
                imsi_start: { rule: 'PhoneNumber', message: 'Please, enter valid phone number' },
            }

            for (const key in fields) {
                if (PrepayMobileValidator.isRuleExists(fields[key].rule)) {
                    if (PrepayMobileValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors.fields[key] = PrepayMobileValidator.getError()
                        is_valid = false
                    }
                }
            }

            if (!+this.number_to_allocate) {
                this.errors.fields.number_to_allocate = 'Please, enter valid number of MSISDNs'
                is_valid = false
            }

            return is_valid
        },
    },

    computed: {
        phone_number_mask() {
            return '999999999999'
        },

        number_to_allocate_mask() {
            return {
                regex: '[0-9]*'
            }
        },
    },
}
</script>

<style lang="scss">
.page-prepay-mobile-preactivation-reserve {
    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .form-container {
            margin-bottom: 24px;

            .form-group {
                display: flex;
                flex-wrap: wrap;

                margin-left: -15px;
                margin-right: -15px;

                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }

                .form-controls {
                    flex: 0 0 50%;
                    max-width: 50%;

                    padding: 0 15px;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-prepay-mobile-preactivation-reserve {
        .board {
            padding: 24px 8px;

            .form-container {
                .form-group {
                    margin-bottom: 24px;

                    .form-controls {
                        flex: 0 0 100%;
                        max-width: 100%;

                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>